import { AsyncGet, AsyncDelete } from "Global/api";
import { CONSTANTS } from "./reducer";

export function setActiveMenuOption(
	activeMenuOption,
	highlightedFlowId = null
) {
	return {
		type: CONSTANTS.UPDATE_ACTIVE_MENU_OPTION,
		payload: { activeMenuOption, highlightedFlowId },
	};
}

export function updateFlowID(flowID, localID, newID) {
	return {
		type: CONSTANTS.UPDATE_FLOW_ID,
		payload: { flowID, localID, newID },
	};
}

export function addFlowChild(child) {
	return {
		type: CONSTANTS.ADD_FLOW_CHILD,
		payload: child,
	};
}

export function updateFlowChild(id, data) {
	return {
		type: CONSTANTS.UPDATE_FLOW_CHILD,
		payload: { id, data },
	};
}

export function setFlows(flows) {
	return {
		type: CONSTANTS.SET_FLOWS,
		payload: flows,
	};
}

export function addFlow(flow) {
	return {
		type: CONSTANTS.ADD_FLOW,
		payload: flow,
	};
}

export function updateFlow(id, data) {
	return {
		type: CONSTANTS.UPDATE_FLOW,
		payload: { id, data },
	};
}

export function reorderChild(flowId, fromIndex, toIndex, callback) {
	return {
		type: CONSTANTS.REORDER_CHILD,
		payload: { flowId, fromIndex, toIndex, callback },
	};
}

export function duplicateChild(flowId, childId) {
	return {
		type: CONSTANTS.DUPLICATE_CHILD,
		payload: { flowId, childId },
	};
}

export function deleteChild(flowId, childId) {
	return async (dispatch) => {
		if (typeof childId !== "string") {
			try {
				await AsyncDelete("v2/automation/flow/" + childId);
			} catch (e) {
				// console.log(e);
			}
		}

		return dispatch({
			type: CONSTANTS.DELETE_CHILD,
			payload: { flowId, childId },
		});
	};
}

export function setIntegrations(integrations) {
	return {
		type: CONSTANTS.SET_INTEGRATIONS,
		payload: integrations,
	};
}

export function forceFlowToRun(flowID, callback) {
	return () => {
		AsyncGet(`v2/automation/flow/${flowID}/run/force_past_conditions`)
			.then(() => {
				callback(true);
			})
			.catch(() => {
				callback(false);
			});
	};
}
